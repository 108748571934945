<template>
  <div class="tpl_itm_wrap bdc_panel">
    <div v-if="!loading && !isNoData">
      <div
        v-if="showTitleAsDropdown"
        class="dropdown pnl_top_drop noselect"
        :class="[showDropdown ? 'active' : '']"
        style="width: 100%; max-width: 100%;"
        tabindex="1"
      >
        <div class="select" @click="showDropdown = !showDropdown">
          <div class="topdropspan">{{ field1.title }}</div>
          <font-awesome-icon
            :icon="['far', 'chevron-down']"
            class="droparw"
            :class="showDropdown ? 'rotate-chevron' : ''"
          />
        </div>
        <ul
          v-if="showDropdown"
          :id="dropdownId"
          class="dropdown-menu-multi checks"
          style="display: block;"
        >
          <li
            v-for="(option, i) in optionsCopy"
            :key="i"
            class="pe pe3b drp-itm2 dropdown-content-selected"
            @click="onOptionClick(option, i)"
            @mouseover="(el) => handleMouseOver(el, i + 1, option.value || option)"
            @mouseleave="handleMouseLeave"
          >
            <font-awesome-icon
              class="drop-ck show"
              :class="{ 'check-off': !option.checked }"
              :icon="['fa', 'check']"
              size="6x"
            />
            <span class="norm-option-item">{{ option.value || option }}</span>
          </li>
        </ul>
      </div>
      <div v-else class="pnl_itm_title">
        <div class="pnl_itm_title_txt active">{{ field1.title }}</div>
      </div>
      <div class="tpl_itm_mid">
        <div
          class="tpl_itm_value intip1"
          :data-tool-tip="formattedField1.tooltip"
          :class="
            field1.valueFormat == 'CURRENCY' && field1.current.value === 0 ? 'no-tooltip' : ''
          "
        >
          <div class="initpHeadValue">
            <font-awesome-icon
              v-if="formattedField1.tooltip"
              :icon="['fas', showCopiedText['field1'] ? 'check' : 'copy']"
              @click="handleCopyText(formattedField1.tooltip, 'field1')"
            />
            {{ formattedField1.head }}
            <span v-if="formattedField1.tail" class="val_type">{{ formattedField1.tail }}</span>
          </div>
        </div>
        <div
          v-if="showChange"
          class="tpl_itm_change intip2 tooltipCopy"
          :class="changeClassName(field1)"
          :data-tool-tip="formattedChangeTooltipValue(field1)"
        >
          <div class="initpHeadValue">
            <font-awesome-icon
              v-if="formattedChangeTooltipValue(field1)"
              :icon="['fas', showCopiedText['field1ChangeValue'] ? 'check' : 'copy']"
              @click="handleCopyText(formattedChangeTooltipValue(field1), 'field1ChangeValue')"
            />
            {{ formattedChangeValue(field1) }}
            <font-awesome-icon :icon="['fas', 'arrow-up-right']" class="change_arw" />
          </div>
        </div>
      </div>
      <div v-if="!hideField2" class="tpl_itm_btm">
        <div class="tpl_itm_val2 intip2 tooltipCopy" :data-tool-tip="formattedField2.tooltip">
          <div class="initpHeadValue">
            <font-awesome-icon
              v-if="formattedField2.tooltip"
              :icon="['fas', showCopiedText['field2'] ? 'check' : 'copy']"
              @click="handleCopyText(formattedField2.tooltip, 'field2')"
            />
            {{ formattedField2.head }}{{ formattedField2.tail }}
          </div>
        </div>
        <div class="tpl_itm_label2">{{ field2.title }}</div>
        <div
          v-if="showChange"
          class="tpl_itm_change2 intip2 tooltipCopy"
          :class="changeClassName(field2)"
          :data-tool-tip="formattedChangeTooltipValue(field2)"
        >
          <div class="initpHeadValue">
            <font-awesome-icon
              v-if="formattedChangeTooltipValue(field2)"
              :icon="['fas', showCopiedText['field2ChangeValue'] ? 'check' : 'copy']"
              @click="handleCopyText(formattedChangeTooltipValue(field2), 'field2ChangeValue')"
            />
            {{ formattedChangeValue(field2) }}
            <font-awesome-icon :icon="['fas', 'arrow-up-right']" class="change_arw" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && isNoData" class="txt-center">No data</div>
    <b-loading-spinner v-if="loading" class="txt-center" />
  </div>
</template>

<script>
import _ from 'underscore';
import formatNumber, { formatNumberAsFullNumber } from '~/util/numeral';
import { isFormattedValueZero } from '~/util/utility-functions';
import { uuid } from '~/helpers/global/misc-helpers';

export default {
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  props: {
    field1: {
      type: Object,
      required: true,
      default: () => {},
    },
    field2: {
      type: Object,
      required: false,
      default: () => {},
    },
    showChange: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    hideField2: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showTitleAsDropdown: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    dropdownOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      formatNumber,
      showCopiedText: {
        field1: false,
        field1ChangeValue: false,
        field2: false,
        field2ChangeValue: false,
      },
      showDropdown: false,
      optionsCopy: JSON.parse(JSON.stringify(this.dropdownOptions)) || [],
      activeEl: { style: { display: 'none' }, text: '' },
      dropdownId: uuid(),
    };
  },
  computed: {
    formattedField1() {
      return this.formattedCurrentValue(this.field1);
    },
    formattedField2() {
      return this.formattedCurrentValue(this.field2);
    },
    isNoData() {
      if (this.hideField2) {
        return _.isEmpty(this.field1?.title);
      }
      return _.isEmpty(this.field1?.title) && _.isEmpty(this.field2?.title);
    },
  },
  watch: {
    dropdownOptions: {
      deep: true,
      handler(n) {
        this.optionsCopy = JSON.parse(JSON.stringify(n));
      },
    },
  },
  mounted() {
    window.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    onOptionClick(option, index) {
      option.checked = !option.checked;
      this.handleMouseLeave();
      this.$emit('selected-options', this.optionsCopy, false, index);
    },
    onClickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    handleMouseOver(el, i, text) {
      const ulElement = document.getElementById(this.dropdownId);
      if (!text) {
        return;
      }
      this.activeEl = {
        style: {
          top: `${i * 39.2 - ulElement.scrollTop + 80}px`,
          left: `${25}px`,
          display: 'block',
        },
        text,
      };
    },
    handleMouseLeave() {
      this.activeEl = { style: { display: 'none' }, text: '' };
    },
    async handleCopyText(text, id) {
      if (this.showCopiedText[id]) {
        return;
      }
      this.showCopiedText[id] = true;
      await navigator.clipboard.writeText(text);
      setTimeout(() => {
        this.showCopiedText[id] = false;
      }, 1000);
    },
    changeClassName(f) {
      if (!f) {
        return '';
      }
      const { difference, valueFormat, decimals, optionalDecimals } = f;
      if (!difference) {
        return '';
      }
      const formattedValue = formatNumber(
        difference.value,
        valueFormat,
        decimals,
        optionalDecimals
      );
      const isZero = isFormattedValueZero(formattedValue);
      if (isZero) {
        return '';
      }
      if (f.title === 'CPA') {
        return { cpa_cg_up: difference.value > 0, cpa_cg_down: difference.value < 0 };
      }
      return { cg_up: difference.value > 0, cg_down: difference.value < 0 };
    },
    formattedChangeValue(f) {
      if (!f) {
        return '';
      }
      const { difference, valueFormat, decimals, optionalDecimals } = f;
      return this.formattedSignedChangeValue(difference, valueFormat, decimals, optionalDecimals);
    },
    formattedSignedChangeValue(o, format, decimals, optionalDecimals) {
      if (!o) {
        return '';
      }
      if (o.isInfinity) {
        return 'NA';
      }

      const formattedValue = formatNumber(o.value, format, decimals, optionalDecimals);
      const isZero = isFormattedValueZero(formattedValue);
      let sign = '';
      if (!isZero) {
        if (o.value < 0) {
          sign = '-';
        } else if (o.value > 0) {
          sign = '+';
        }
      }
      return `${sign}${formattedValue}`;
    },
    formattedChangeTooltipValue(f) {
      if (!f) {
        return null;
      }
      if (!f.current.value) {
        return null;
      }
      const { difference, valueFormat, decimals, optionalDecimals } = f;
      return this.formattedSignedChangeTooltipValue(
        difference,
        valueFormat,
        decimals,
        optionalDecimals
      );
    },
    formattedSignedChangeTooltipValue(o, format, decimals, optionalDecimals) {
      if (!o) {
        return null;
      }
      if (o.isInfinity) {
        return null;
      }

      const formattedValue = formatNumberAsFullNumber(o.value, format, decimals, optionalDecimals);
      const isZero = isFormattedValueZero(formattedValue);
      if (isZero) {
        return null;
      }
      let sign = '';
      if (o.value > 0) {
        sign = '+';
      }
      return `${sign}${formattedValue}`;
    },
    formattedCurrentValue(f) {
      if (!f) {
        return {};
      }
      const { current, valueFormat, decimals, optionalDecimals } = f;
      if (!current) {
        return {};
      }

      if (current.isInfinity) {
        return { head: 'NA' };
      }
      const formattedValue = formatNumber(current.value, valueFormat, decimals, optionalDecimals);
      let formattedTooltipValue = formatNumberAsFullNumber(
        current.value,
        valueFormat,
        decimals,
        optionalDecimals
      );
      const isTooltipZero = isFormattedValueZero(formattedTooltipValue);
      if (isTooltipZero) {
        formattedTooltipValue = null;
      }
      const frNS = formattedValue.split('');

      let head = frNS.splice(0, frNS.length - 1).join('');
      let tail = frNS.pop();

      if (!Number.isNaN(Number(tail))) {
        tail = undefined;
        head = formattedValue;
      }

      return {
        head,
        tail,
        tooltip: formattedTooltipValue,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.initpHeadValue .fa-check,
.initpHeadValue .fa-copy {
  display: none !important;
  font-size: 12px !important;
  color: #ffffff;
  text-align: center;
  position: absolute;
  padding: 3px;
  background-color: #1a1b1d;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 999;
  left: 5%;
  bottom: -114%;
}
.fa-copy {
  cursor: pointer;
}
.intip2 .fa-copy {
  bottom: -75%;
}

@media (max-width: 900px) {
  .tpl_itm_wrap {
    flex-grow: 1; /* Allows the items to grow and fill available space */
    flex-basis: 0; /* Starts with zero space, grows as much as needed */
    width: calc(50% - 10px) !important;
    padding: 9px 17px 11px !important;
  }
  .tpl_itm_value {
    font-size: 24px !important;
  }
  .bdc_panel {
    margin-bottom: 20px !important;
    float: none;
  }
}

.tpl_itm_wrap {
  display: inline-block;
  width: calc(33% - 18px);
  padding: 15px 25px 20px;
  flex-grow: 1; /* Allows the items to grow and fill available space */
  flex-basis: 0; /* Starts with zero space, grows as much as needed */
  // overflow: scroll;
}
.bdc_panel {
  padding: 19px 25px 25px 25px;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(0, 40, 60, 0.07);
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  background-color: #fff;
  margin-bottom: 30px;
  border: 1px solid rgb(165 173 178 / 22%);
}
.pnl_itm_title {
  border-bottom: 1px solid #e0e5ee;
  padding-bottom: 0px;
  white-space: nowrap;
}

.pnl_itm_title_txt {
  display: inline-block;
  position: relative;
  border-bottom: 0px solid;
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  color: var(--primarylight);
  font-size: 1.02em;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
.pnl_itm_title_txt:hover {
  color: var(--primarydark);
}
.pnl_itm_title_txt.active {
  display: inline-block;
  position: relative;
  border-bottom: 3px solid var(--primarycolor);
  padding-bottom: 15px;
  top: 2px;
  z-index: 9;
  font-weight: 600;
  color: var(--primarydark);
}

.tpl_itm_mid {
  margin: 24px 0px 15px;
  white-space: nowrap;
}
.tpl_itm_value {
  font-size: 35px;
  font-weight: 600;
  color: var(--primarydark);
  display: inline-block;
  margin-right: 6px;
}
.val_type {
  font-weight: 100;
  font-size: 0.9em;
  position: relative;
  top: -1px;
}
.tpl_itm_change {
  display: inline-block;
  font-size: 9px;
  font-weight: 700;
  border-radius: 4px;
  padding: 0px 5px;
  color: #777777;
  background-color: #ededed;
  vertical-align: top;
  min-width: 33px;
  text-align: center;
}

.cg_up {
  color: #15aaad;
  background-color: #e6f3f3;
}
.cpa_cg_up {
  color: #b73232;
  background-color: #e6f3f3;
}
.cpa_cg_down {
  color: #15aaad;
  background-color: #e6f3f3;
}
.cpa_cg_down .change_arw {
  transform: rotate(90deg);
}
.change_arw {
  display: none;
  margin-left: 2px;
}
.cg_up .change_arw,
.cg_down .change_arw,
.cpa_cg_up .change_arw,
.cpa_cg_down .change_arw {
  display: inline-block;
}
.cg_down {
  color: #b73232;
  background-color: #faecec;
}
.cg_down .change_arw {
  transform: rotate(90deg);
}
.tpl_itm_btm {
  white-space: nowrap;
}
.tpl_itm_val2 {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
}
.tpl_itm_label2 {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  color: var(--primarylight);
  margin-right: 3px;
}
.tpl_itm_change2 {
  display: inline-block;
  background-color: transparent;
  font-size: 11px;
  font-weight: 600;
}

.txt-center {
  text-align: center;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Tooltip - Start
::v-deep .intip1[data-tool-tip].no-tooltip {
  &::after,
  svg {
    display: none !important;
  }
}
.intip1,
.intip2 {
  position: relative;
  top: 0;
  width: max-content;
  .initpHeadValue {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.tooltipCopy:hover .fa-check {
    bottom: -79%;
  }

  &:hover .fa-check,
  &:hover .fa-copy {
    display: block !important;
    display: block !important;
  }
}
.intip1[data-tool-tip]::after,
.intip2[data-tool-tip]::after {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 99;
  display: block;
  width: 100px;
  padding: 10px 12px;
  margin-left: 0;
  font-size: 12px;
  line-height: 1.2em;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  transform-origin: bottom center;
}
.intip1[data-tool-tip]::after,
.intip2[data-tool-tip]::after {
  width: auto;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  pointer-events: none;
  background-color: rgb(44 48 54 / 98%);
}

.intip2[data-tool-tip]::before {
  left: 10px;
}
.intip2[data-tool-tip]::before,
.intip1[data-tool-tip]::before {
  content: '';
  font-size: 12px;
  width: 100%;
  height: 26px;
  display: inline-block;
  margin: 20px 0px 0px 0px;
  position: absolute;
  padding-top: 8px;
  text-align: right;
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.intip1[data-tool-tip]::after,
.intip2[data-tool-tip]::after {
  background-color: #1a1b1d;
  border-radius: 4px;
  box-shadow: none;
  text-align: left;
  font-size: 11px;
  padding: 3px 6px;
  color: #fff;
  transition: opacity 0.6s ease-in-out;
  visibility: visible;
  width: -moz-max-content;
  width: max-content;
  padding-left: 25px !important;
}
.intip1[data-tool-tip]:hover::after {
  bottom: -120%;
  transform: scale(1);
}
.intip2[data-tool-tip]:hover::after {
  bottom: -80%;
  transform: scale(1);
}
// Tooltip - End

/* =========================== MOBILE STYLES =========================== */
@media (max-width: 900px) {
  .tpl_itm_wrap {
    width: calc(50% - 10px);
    padding: 9px 17px 11px;
  }
  .bdc_panel {
    width: 100%;
    margin-bottom: 20px;
    float: none;
  }
  .tpl_itm_mid {
    margin: 15px 0px 7px;
    white-space: nowrap;
  }
  .tpl_itm_value {
    font-size: 24px;
  }
}

@media (max-width: 1300px) {
  .tpl_itm_value {
    font-size: 32px;
  }
  .tpl_itm_val2 {
    font-size: 14px;
  }
}

.check {
  width: 1em;
  padding: 2px;
  color: var(--primarycolor);
  border-radius: 30px;
  box-shadow: inset 0px 0px 1px 1px #2aa4af70;
  &.left {
    margin-right: 0.5rem;
  }
  &.wl {
    color: var(--primarycolor) !important;
  }
}
.check-off {
  color: transparent !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.selected-opt-header {
  font-size: 14px;
  color: #707e8a;
}
.selected-opt-wrapper {
  display: inline-flex;
  width: 90%;
  white-space: nowrap;
}
.selected-opt {
  display: inline-block;
  width: 90%;
  margin-left: 0.2rem;
  overflow: hidden;
  font-size: 14px;
  color: #cad1d6;
  text-overflow: ellipsis;
  white-space: nowrap;
}
svg.reverse {
  transform: rotate(180deg);
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}

@media screen {
  * {
    box-sizing: border-box;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  * {
    outline: 0;
  }
  .dropdown {
    min-width: 150px;
    max-width: 240px;
    display: inline-block;
    border-radius: 6px;
    transition: all 0.3s ease;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    color: var(--primarydark2);
    text-align: left;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Opera and Firefox */
  }
  .dropdown .select {
    display: block;
    cursor: pointer;
    padding: 8px 20px 8px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dropdown .select .droparw {
    transition: all 0.2s ease-in-out;
  }
  .dropdown .select .droparw.rotate-chevron {
    transform: rotate(180deg);
  }
  .dropdown .dropdown-menu,
  .dropdown .dropdown-menu-multi {
    position: absolute;
    left: 0;
    z-index: 999;
    width: calc(100% + 2px);
    max-height: 338px;
    margin-top: 0px;
    margin-left: -1px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 0 1px 4px 4px;
    box-shadow: 0px 5px 3px 1px rgb(115 124 143 / 10%);
    border-right: 1px solid #c6d2d8;
    border-left: 1px solid #c6d2d8;
    border-bottom: 1px solid #c6d2d8;
  }
  .dropdown .dropdown-menu li,
  .dropdown .dropdown-menu-multi li {
    padding: 5px 10px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    border-bottom: 1px solid #2b355814;
  }
  .dropdown .dropdown-menu-multi li {
    padding: 10px 10px 10px 30px;
    font-weight: 500;
  }
  .dropdown .dropdown-menu li.dropdown-content-selected {
    display: flex;
  }
  .dropdown .dropdown-menu,
  .dropdown .dropdown-menu-multi {
    padding: 0;
    list-style: none;
  }
  .dropdown .dropdown-menu,
  .dropdown .dropdown-menu-multi {
    padding: 0px 0px 0px 0px;
    list-style: none;
  }
  .dropdown .dropdown-menu li:hover,
  .dropdown .dropdown-menu-multi li:hover {
    white-space: normal;
    background-color: rgb(116 116 143 / 6%) !important;
  }
  .dropdown .dropdown-menu li:hover,
  .dropdown .dropdown-menu-multi li:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .dropdown .dropdown-menu li:active,
  .dropdown .dropdown-menu-multi li:active {
    background-color: #333;
  }

  .drop-ck {
    float: right;
    position: relative;
    top: 2px;
    color: var(--primarycolor);
    border: 0px solid;
    box-shadow: inset 0px 0px 1px 1px var(--primarycolor);
    padding: 2px 3px 3px;
    top: 2px;
    left: 8px;
    background: transparent;
    font-size: 11px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    width: 11px;
    height: 11px;
  }

  .dropdown-menu-multi .drop-ck {
    float: none;
    color: var(--primarycolor);
    border: 0px solid;
    box-shadow: inset 0px 0px 1px 1px var(--primarycolor);
    padding: 2px 3px 3px;
    top: 12px;
    left: 8px;
    background: transparent;
    font-size: 11px;
    border-radius: 3px;
    display: inline-block;
    margin-right: 10px;
    width: 11px;
    height: 11px;
    position: absolute;
  }

  .dropdown-menu-multi .sbm_i {
    float: none;
    background: transparent;
    display: inline-block;
    margin-left: 8px;
    margin-right: 4px;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(111, 111, 111, 0.2);
    border: 0 #fff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: 0 0;
    border: 0 #fff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: 0 0;
  }
  ::-webkit-scrollbar-track:active {
    background: 0 0;
  }
  ::-webkit-scrollbar-corner {
    background: 0 0;
  }
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

.fa-chevron-down:before {
  content: '\f078';
}
.fa-star:before {
  content: '\f005';
}

/*top small panel dropdown*/
.dropdown.pnl_top_drop {
  border: 0px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
  font-weight: 600;
  color: var(--primarydark);
  font-size: 1.02em;
  margin-top: -6px;
}
.dropdown.pnl_top_drop .select {
  padding: 8px 20px 3px 0px;
  overflow: visible;
}
.dropdown.pnl_top_drop:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
  outline: none;
}
.topdropspan {
  border-bottom: 3px solid var(--primarycolor);
  padding-bottom: 2px;
  position: relative;
  display: inline-block;
  margin-bottom: -10px;
}
.dropdown.pnl_top_drop .droparw {
  right: auto;
  margin-top: -3px;
  margin-left: 8px;
}

.pnl_top_drop .dropdown-menu-multi .drp-itm-arw,
.sm_pnl_subtop .dropdown-menu-multi .drp-itm-arw {
  font-size: 13px;
  right: 10px;
  margin-top: 4px;
}
.clicked .drp-itm-arw {
  transform: rotate(180deg);
}

.dropdown.pnl_top_drop .dropdown-menu,
.dropdown.pnl_top_drop .dropdown-menu-multi {
  margin-top: 2px;
  color: var(--primarydark2);
  font-weight: 500;
}

.sm_pnl_subtop.sbt_right .dropdown-menu,
.sm_pnl_subtop.sbt_right .dropdown-menu-multi {
  margin-left: 0px;
  right: 0px;
  left: auto;
}

.dropdown.pnl_top_drop .mdrp-sub {
  font-size: 0.8em;
  padding: 10px 12px 9px 30px !important;
  position: relative;
  line-height: 1.2em;
}
.dropdown.pnl_top_drop .dropdown-menu-multi li {
  padding: 10px 10px 10px 4px;
  font-size: 0.9em;
}
.dropdown.pnl_top_drop .dropdown-menu-multi.checks li {
  padding: 10px 10px 10px 30px;
}
.pnl_top_drop .dropdown-menu-multi .mdrp-sub-itms .drop-ck {
  left: 10px;
  top: 10px;
}
.topdropi {
  width: 25px;
  text-align: center;
}

.pnl_top_drop .sbm_i {
  margin-left: 3px;
  margin-right: 4px;
}

.dropdown.pnl_top_drop .dropdown-menu-multi.wchecks li {
  padding: 10px 10px 10px 27px;
}

.drp-grp {
  position: relative;
}

.nochecks .fa-check {
  display: none;
}

.dropdown .dropdown-menu li.dropdown-content-selected,
.dropdown .dropdown-menu-multi.checks li.dropdown-content-selected,
.dropdown .dropdown-menu-multi.checks .drp-grp.dropdown-content-selected {
  box-shadow: inset 0px 0px 1px -1px var(--primarycolor);
}
</style>
