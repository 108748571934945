<template>
  <div
    id="advance_filters"
    class="sub_filters_drop_wrap "
    :class="`${showAdvancedFilters ? 'showtopdrp' : ''}`"
  >
    <div class="sub_filters_drop ease">
      <div class="sfd_right">
        <div class="pnl_itm_title " style="width: calc(100% - 42px);">
          <div class="pnl_itm_title_txt advfltr-btn active">
            <font-awesome-icon
              :icon="['far', 'filter']"
              class="fa-regular fa-filter sfd_i"
            />Advanced Filters
          </div>

          <div class="sfd_close" @click="handleCloseAdvanceFilter">
            <div class="sfd_close_icon">
              <font-awesome-icon :icon="['far', 'times']" />
            </div>
          </div>
        </div>
        <div class="panel_scroll_wrap filter-drop-scroll">
          <div class="sub_filters_advanced_content">
            <div
              v-if="showCampaignFilter || showAdGroupFilter"
              class="io-adgroup-filter-container"
              style="text-align: left; margin: 0px 0px 30px 0px;"
            >
              <div v-if="showCampaignFilter" class="advance_filters_container">
                <!-- // ContractD ID name should be picked from constant file -->
                <div v-if="isViaAccount" class="fltr-label">Filter by Contract ID</div>
                <div v-else class="fltr-label">Filter by Campaigns</div>
                <dropdown-checkbox-search
                  key="campaign"
                  name="campaign"
                  label="Search..."
                  :options="filters.campaignOptions"
                  :fancy="true"
                  :multiple="true"
                  :loading="isAdvanceFilterLoading && !isMniAccount"
                  label-key="value"
                  title=""
                  header="Campaigns"
                  component-id="ioOptionDropdown"
                  :show-advanced-filters="showAdvancedFilters"
                  :show-all="false"
                  :value="selectedFilters.campaignOptions ? selectedFilters.campaignOptions : []"
                  original-component="dropdown"
                  :class-name="
                    `advance-filter-hover-campaign ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    }`
                  "
                  @input="(val) => setSelectedFilters('campaignOptions', val)"
                  @selected-options="(val) => setSelectedFilters('campaignOptions', val, true)"
                />
              </div>

              <div v-if="showAdGroupFilter" class="advance_filters_container margin-left">
                <div v-if="isViaAccount" class="fltr-label">Filter by ConLine ID</div>
                <div v-else class="fltr-label">Filter by Ad Group</div>
                <dropdown-checkbox-search
                  key="adGroup"
                  name="adGroup"
                  label="Search..."
                  :options="filters.adGroupOptions"
                  :fancy="true"
                  :multiple="true"
                  :loading="isAdvanceFilterLoading && !isMniAccount"
                  label-key="value"
                  title=""
                  header="Ad Group"
                  class="media-select"
                  component-id="adGroupDropdowns"
                  :show-advanced-filters="showAdvancedFilters"
                  :show-all="false"
                  :value="selectedFilters.adGroupOptions ? selectedFilters.adGroupOptions : []"
                  original-component="dropdown"
                  :class-name="
                    `advance-filter-hover-adgroup ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    }`
                  "
                  :disabled="isMniAccount"
                  @input="(val) => setSelectedFilters('adGroupOptions', val)"
                  @selected-options="(val) => setSelectedFilters('adGroupOptions', val, true)"
                />
              </div>
            </div>

            <!-- If SolutionId == 1 or 2 for brand/media search-->
            <template v-if="showMediaTypeFilter">
              <div class="fltr-label">Filter by Media Type</div>
              <div class="form-group hover-options-dropdown">
                <dropdown-checkbox-search
                  key="mediaType"
                  name="mediaType"
                  label="Search..."
                  :options="filters.mediaTypeOptions"
                  :fancy="true"
                  :multiple="true"
                  :loading="isAdvanceFilterLoading"
                  label-key="value"
                  original-component="autoComplete"
                  :value="selectedFilters.mediaTypeOptions ? selectedFilters.mediaTypeOptions : []"
                  :class-name="
                    `advance-filter-hover-mediatype ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    }`
                  "
                  @input="(val) => setSelectedFilters('mediaTypeOptions', val)"
                />
              </div>
              <div style="height: 30px;"></div>
            </template>
            <template v-if="showCreativeFilter">
              <div class="fltr-label">Filter by Creative</div>
              <div class="form-group hover-options-dropdown">
                <dropdown-checkbox-search
                  key="creatives"
                  label="Search..."
                  :options="filters.creativeOptions"
                  :loading="isAdvanceFilterLoading"
                  :fancy="true"
                  :multiple="true"
                  label-key="value"
                  original-component="autoComplete"
                  :value="selectedFilters.creativeOptions ? selectedFilters.creativeOptions : []"
                  :class-name="
                    `advance-filter-hover-creatives ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    } `
                  "
                  @input="(val) => setSelectedFilters('creativeOptions', val)"
                />
              </div>
              <div style="height: 30px;"></div>
            </template>
            <template v-if="showAudienceFilter">
              <div v-if="isViaAccount" class="fltr-label">Filter by Tier</div>
              <div v-else class="fltr-label">{{ filterLabel }}</div>
              <div class="form-group hover-options-dropdown">
                <dropdown-checkbox-search
                  key="audience"
                  label="Search..."
                  :options="filters.audienceOptions"
                  :loading="isAdvanceFilterLoading"
                  :fancy="true"
                  :multiple="true"
                  label-key="value"
                  original-component="autoComplete"
                  :value="selectedFilters.audienceOptions ? selectedFilters.audienceOptions : []"
                  :class-name="
                    `advance-filter-hover-audiences ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    }`
                  "
                  @input="(val) => setSelectedFilters('audienceOptions', val)"
                />
              </div>
              <div style="height: 30px;"></div>
            </template>
            <template v-if="showAdFilter">
              <div class="fltr-label">Filter by Ads</div>
              <div class="form-group hover-options-dropdown">
                <!-- <b-auto-complete-dropdown
                  key="creatives"
                  label="Search..."
                  :options="filters.creativeOptions"
                  :loading="isAdvanceFilterLoading"
                  :disabled="disabledCreatives"
                  :fancy="true"
                  :multiple="true"
                  label-key="value"
                  :value="selectedFilters.creativeOptions ? selectedFilters.creativeOptions : []"
                  :class-name="
                    `advance-filter-hover-creatives ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    } `
                  "
                  @input="(val) => setSelectedFilters('creativeOptions', val)"
                /> -->
                <dropdown-checkbox-search
                  key="creatives"
                  label="Search..."
                  :options="filters.creativeOptions"
                  :loading="isAdvanceFilterLoading"
                  :disabled="disabledCreatives"
                  :fancy="true"
                  :multiple="true"
                  label-key="value"
                  original-component="autoComplete"
                  :value="selectedFilters.creativeOptions ? selectedFilters.creativeOptions : []"
                  :class-name="
                    `advance-filter-hover-creatives ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    }`
                  "
                  @input="(val) => setSelectedFilters('creativeOptions', val)"
                />
              </div>
              <div style="height: 30px;"></div>
            </template>
            <template v-if="showKeywordFilter">
              <div class="fltr-label">Filter by Keywords</div>
              <div class="form-group hover-options-dropdown">
                <!-- <b-auto-complete-dropdown
                  label="Search..."
                  :options="filters.keywordOptions"
                  :disabled="disabledKeywords"
                  :loading="isAdvanceFilterLoading"
                  :fancy="true"
                  :multiple="true"
                  label-key="value"
                  :value="selectedFilters.keywordOptions ? selectedFilters.keywordOptions : []"
                  :class-name="
                    `advance-filter-hover-mediatype ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    }`
                  "
                  @input="(val) => setSelectedFilters('keywordOptions', val)"
                /> -->
                <dropdown-checkbox-search
                  key="keywords"
                  label="Search..."
                  :options="filters.keywordOptions"
                  :loading="isAdvanceFilterLoading"
                  :disabled="disabledKeywords"
                  :fancy="true"
                  :multiple="true"
                  label-key="value"
                  original-component="autoComplete"
                  :value="selectedFilters.keywordOptions ? selectedFilters.keywordOptions : []"
                  :class-name="
                    `advance-filter-hover-keywords ${
                      isAdvanceFilterLoading ? 'loading-dropdown' : ''
                    }`
                  "
                  @input="(val) => setSelectedFilters('keywordOptions', val)"
                />
              </div>
              <div style="height: 30px;"></div>
            </template>
            <template v-if="showConversionWindowFilter">
              <div class="fltr-label">Filter by Conversion Window (In Days)</div>
              <div class="form-group">
                <input
                  ref="conversionWindowInput"
                  v-model="filters.conversionWindow"
                  type="text"
                  name="conversion-window"
                  class="flp-input input-conv"
                  @input="validateFilterData"
                />
              </div>
              <div v-if="error" class="error">{{ error }}</div>
              <div style="height: 30px;"></div>
            </template>
          </div>
          <!-- advanced filter content end -->

          <div class="sfd_footer">
            <div
              class="mdn-btn btn-size2 sfd-apply-btn"
              style="min-width: 80px;margin-right: 8px;"
              @click="onClickApply"
            >
              Apply
            </div>
            <div class="mdn-btn btn-size2 sfd-cancel-btn outline" @click="handleCloseAdvanceFilter">
              Cancel
            </div>
          </div>
        </div>
      </div>
      <!-- scroll end -->
    </div>
    <!-- filter right end -->
  </div>
  <!-- filter drop end -->
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
// import BDropdown from './b-dropdown.vue';
// import BAutoCompleteDropdown from './b-auto-complete-dropdown2.vue';
import DropdownCheckboxSearch from './b-auto-complete-dropdown3.vue';
import { isFilterOptionEnabled } from '~/util/utility-functions';
import {
  SEARCH_SOLUTION_ID,
  CAMPAIGN_FILTER,
  ADGROUP_FILTER,
  MEDIATYPE_FILTER,
  CREATIVE_FILTER,
  AUDIENCE_FILTER,
  AD_FILTER,
  KEYWORD_FILTER,
  CONVERSION_WINDOW_FILTER,
} from '~/constant';

export default {
  // components: { BAutoCompleteDropdown, DropdownCheckboxSearch },
  components: { DropdownCheckboxSearch },
  props: {
    showAdvancedFilters: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdvanceFilterLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialFilters: {
      type: Object,
      required: false,
      default: () => {},
    },
    activeSolutionId: {
      type: Number,
      required: false,
      default: 1,
    },
    filtersToHide: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    const showCampaignFilter = isFilterOptionEnabled(this.filtersToHide, CAMPAIGN_FILTER);
    const showAdGroupFilter = isFilterOptionEnabled(this.filtersToHide, ADGROUP_FILTER);
    const showMediaTypeFilter = isFilterOptionEnabled(this.filtersToHide, MEDIATYPE_FILTER);
    const showCreativeFilter = isFilterOptionEnabled(this.filtersToHide, CREATIVE_FILTER);
    const showAudienceFilter = isFilterOptionEnabled(this.filtersToHide, AUDIENCE_FILTER);
    const showAdFilter = isFilterOptionEnabled(this.filtersToHide, AD_FILTER);
    const showKeywordFilter = isFilterOptionEnabled(this.filtersToHide, KEYWORD_FILTER);
    const showConversionWindowFilter = isFilterOptionEnabled(
      this.filtersToHide,
      CONVERSION_WINDOW_FILTER
    );
    const filterOptionsArray = [
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'campaignOptions',
      'keywordOptions',
    ];
    return {
      errorMsg: null,
      filters: JSON.parse(JSON.stringify(this.initialFilters)),
      selectedFilters: this.getSelectedFilters(this.initialFilters, filterOptionsArray),
      filterOptionsArray,
      showCampaignFilter,
      showAdGroupFilter,
      showMediaTypeFilter,
      showCreativeFilter,
      showAudienceFilter,
      showAdFilter,
      showKeywordFilter,
      showConversionWindowFilter,
      disabledCreatives: false,
      disabledKeywords: false,
    };
  },

  computed: {
    isMniAccount: get('common/isMniAccount'),
    isViaAccount: get('common/isViaAccount'),
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
    filterLabel() {
      return this.isMniAccount ? 'Filter by Placements' : 'Filter by Audience';
    },
  },
  watch: {
    showAdvancedFilters(n) {
      if (n) {
        this.filters = JSON.parse(JSON.stringify(this.initialFilters));
        this.selectedFilters = this.getSelectedFilters(
          this.initialFilters,
          this.filterOptionsArray
        );
        this.showCampaignFilter = isFilterOptionEnabled(this.filtersToHide, CAMPAIGN_FILTER);
        this.showAdGroupFilter = isFilterOptionEnabled(this.filtersToHide, ADGROUP_FILTER);
        this.showMediaTypeFilter = isFilterOptionEnabled(this.filtersToHide, MEDIATYPE_FILTER);
        this.showCreativeFilter = isFilterOptionEnabled(this.filtersToHide, CREATIVE_FILTER);
        this.showAudienceFilter = isFilterOptionEnabled(this.filtersToHide, AUDIENCE_FILTER);
        this.showAdFilter = isFilterOptionEnabled(this.filtersToHide, AD_FILTER);
        this.showKeywordFilter = isFilterOptionEnabled(this.filtersToHide, KEYWORD_FILTER);
        this.showConversionWindowFilter = isFilterOptionEnabled(
          this.filtersToHide,
          CONVERSION_WINDOW_FILTER
        );
      }
    },
  },
  created() {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset >= 1) {
        document.getElementById('advance_filters')?.classList?.add('bodyscrolled');
      } else {
        document.getElementById('advance_filters')?.classList?.remove('bodyscrolled');
      }
    });
  },

  methods: {
    clearValidationErrors() {
      this.errorMsg = null;
      this.showMsg = null;
      if (this.showConversionWindowFilter && this.$refs.conversionWindowInput) {
        this.$refs.conversionWindowInput.classList.remove('errorBox');
      }
    },
    validateFilterData() {
      this.clearValidationErrors();

      // If Conversion Window filter disabled then nothing to validate hence always return true
      if (!this.showConversionWindowFilter) {
        return true;
      }

      const value = this.filters.conversionWindow;
      if (isBlank(value) || Number.isNaN(Number(value))) {
        this.errorMsg = 'Conversion window must be a number.';
        this.$refs.conversionWindowInput.focus();
        this.$refs.conversionWindowInput.classList.add('errorBox');
        return false;
      }

      if (Number(value) <= 0 || Number(value) > 90) {
        this.errorMsg = 'Conversion window must be in the range of 1 - 90 days.';
        this.$refs.conversionWindowInput.focus();
        this.$refs.conversionWindowInput.classList.add('errorBox');
        return false;
      }

      return true;
    },
    onClickCancel() {
      this.$emit('on-close');
    },
    onClickApply() {
      const dataValidated = this.validateFilterData();
      if (!dataValidated) {
        return;
      }
      this.$emit('on-apply', this.filters);
    },
    handleCloseAdvanceFilter() {
      this.$emit('on-close');
    },
    getSelectedFilters(filters, filterOptionsArray) {
      const selectFilterVals = {};
      filterOptionsArray?.forEach((fl) => {
        if (filters[fl]?.length) {
          selectFilterVals[fl] = filters[fl]?.filter((f) => f.checked);
        } else {
          selectFilterVals[fl] = [];
        }
      });
      return JSON.parse(JSON.stringify(selectFilterVals));
    },
    setFilterOptions(name, values) {
      this.filters[name] = values;
    },
    setSelectedFilters(name, values, isMultiSelectFilter = false) {
      if (isMultiSelectFilter) {
        this.$parent.getAdvancedFilterData(name, values);
        this.setFilterOptions(name, values);
      } else {
        this.selectedFilters[name] = values.map((val) => {
          val.checked = true;
          return val;
        });
        const formattedOption = this.filters[name].map((opt) => {
          if (values.find((val) => val.value === opt.value)) {
            opt.checked = true;
          } else {
            opt.checked = false;
          }
          return opt;
        });

        // Existing condition to disable keywords or creatives
        if (
          this.activeSolutionId === SEARCH_SOLUTION_ID &&
          this.showAdFilter &&
          this.showKeywordFilter
        ) {
          this.disabledCreatives =
            (name === 'keywordOptions' && this.selectedFilters[name]?.length > 0) ||
            this.selectedFilters.keywordOptions?.length > 0;
          this.disabledKeywords =
            (name === 'creativeOptions' && this.selectedFilters[name]?.length > 0) ||
            this.selectedFilters.creativeOptions?.length > 0;
        }

        this.setFilterOptions(name, formattedOption);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .advance_filters_container .top-span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sopened .sub_filters_drop_wrap {
  padding-left: 280px;
}

::v-deep .advance_filters_container .sub-drop .fdrop-label {
  display: inline-block;
  font-size: 0.82em;
  font-weight: 500;
  position: absolute;
  top: -10px;
  background-color: #ffffff;
  padding: 0px 4px;
  margin-left: -4px;
  color: #969caf;
}
::v-deep .hover-options-dropdown .row.middle-xs.action-icons {
  display: none;
}
::v-deep .hover-options-dropdown .vs__selected-options .vs__search {
  font-size: 14px;
}
::v-deep .loading-dropdown .vs__selected-options {
  display: none;
}
::v-deep .loading-dropdown .vs__actions {
  width: 100%;
}

::v-deep .loading-dropdown .vs__spinner {
  margin: 0 auto;
  border-left-color: var(--primarycolor);
}
.sub_filters_drop_wrap {
  position: absolute;
  z-index: 99999;
  width: 100%;
  left: 0px;
  padding-left: 60px;
  top: -930px;
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.6s ease;
  .ease {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .sub_filters_drop {
    top: 45px;
    background-color: #fff;
    padding: 0px;
    border-radius: 10px;
    border-top: 0px;
    width: 100%;
    max-width: 660px;
    position: relative;
    box-shadow: 0px 18px 40px -6px rgb(88 97 114 / 70%);
    margin: auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    filter: drop-shadow(0px 4px 170px rgb(42 59 90 / 20%));

    .sfd_close_icon {
      font-size: 20px;
      color: var(--primarylight);
      &:hover {
        color: var(--primarycolor);
      }
    }
    .sfd_right {
      display: inline-block;
      width: calc(100% - 252px);
      width: 100%;
      padding: 35px 5px 80px 45px;
      vertical-align: top;
      .bodyscrolled .filter-drop-scroll {
        max-height: calc(100vh - 240px);
      }
      .fltr-label {
        text-align: left;
        font-size: 13px;
        margin: 0px 0px 7px 0px;
        font-weight: 500;
        color: #98a2b1;
      }
      .panel_scroll_wrap {
        overflow-y: scroll;
        height: 100%;
        padding-right: 10px;
        overflow-x: hidden;
      }
      .filter-drop-scroll {
        padding-top: 37px;
        padding-right: 35px !important;
        overflow-y: scroll;
        max-height: calc(100vh - 420px);
        width: 100%;
        padding-bottom: 40px;
        .sfd_footer {
          position: absolute;
          bottom: 0px;
          background-color: #e4eeef;
          text-align: center;
          padding: 20px 45px;
          width: 100%;
          left: 0px;
          border-top: 1px solid #e1e2f1;
          z-index: 2;
          .btn-size2 {
            font-size: 12px;
            padding: 8px 14px;
          }
          .sfd-apply-btn:hover {
            background-color: var(--primarydark2);
          }
          .mdn-btn.outline {
            background-color: transparent;
            color: var(--primarydark2) !important;
            border: 1px solid #c5d2d8;
            &:hover {
              background-color: transparent;
              color: var(--primarycolor) !important;
              border: 1px solid var(--primarycolor);
            }
          }
          .mdn-btn {
            background-color: var(--primarycolor);
            font-size: 12px;
            padding: 8px 14px;
            position: relative;
            color: var(--overtext) !important;
            cursor: pointer;
            -webkit-appearance: none;
            -webkit-border-radius: 0px;
            -moz-appearance: none;
            appearance: none;
            letter-spacing: 0.1em;
            display: inline-block;
            font-weight: 600;
            text-transform: uppercase;
            -webkit-transition: all 0.6s ease;
            -moz-transition: all 0.6s ease;
            -ms-transition: all 0.6s ease;
            -o-transition: all 0.6s ease;
            transition: all 0.6s ease;
            text-align: center;
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, 0);
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }
      }
      .pnl_itm_title {
        border-bottom: 1px solid #e0e5ee;
        padding-bottom: 0px;
        white-space: nowrap;
        .sfd_close {
          display: inline-block;
          position: absolute;
          top: 17px;
          right: 20px;
        }
        .pnl_itm_title_txt {
          display: inline-block;
          position: relative;
          border-bottom: 0px solid;
          padding-bottom: 15px;
          top: 2px;
          z-index: 9;
          color: var(--primarylight);
          font-size: 1.02em;
          font-weight: 600;
          margin-right: 25px;
          cursor: pointer;
          &.active {
            display: inline-block;
            position: relative;
            border-bottom: 3px solid var(--primarycolor);
            padding-bottom: 15px;
            top: 2px;
            z-index: 9;
            font-weight: 600;
            color: var(--primarydark);
          }
          .sfd_i {
            margin-right: 2px;
          }
        }
      }
    }
  }
}
.colheader .sub_filters_drop_wrap.showtopdrp {
  top: 0px;
}
.sub_filters_drop_wrap.showtopdrp {
  opacity: 1;
  height: 100vh;
  padding-top: 23px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sub_filters_drop_wrap.bodyscrolled.showtopdrp {
  top: 45px;
}
.io-adgroup-filter-container {
  display: flex;
}
::v-deep .advance_filters_container {
  &.margin-left {
    margin-left: 12px;
  }
  width: 50%;
  .dropdown {
    border: 1px solid #c5d2d8;
    background-color: #fff;
    min-width: 160px;
    border-radius: 6px;
    width: 273px;
    max-width: unset;
    &:hover {
      border: 1px solid var(--primarycolor);
    }
    .droparw {
      color: #536176;
      // height: 27px;
      // line-height: 37px;
      float: right;
      font-size: 11px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    .select {
      height: 37px;
      padding: 3px 20px 3px 10px;
      min-height: 37px !important;
      display: flex;
      align-items: center;
      border-radius: 6px 6px 0px 0px;
    }
    .select.open {
      background-color: #fff;
    }
    .dropdown-menu {
      background-color: #fff;
      box-shadow: 0px 5px 3px 1px rgba(111, 94, 94, 0.1);
      border-radius: 0 1px 4px 4px;
      border-right: 1px solid #c6d2d8;
      border-left: 1px solid #c6d2d8;
      border-bottom: 1px solid #c6d2d8;
      .norm-option-item {
        color: #536176;
      }
    }
    .selected-opt-wrapper {
      flex-direction: column;
      .selected-opt-header {
        background-color: transparent;
        filter: drop-shadow(0px 0px 5px #fff);
        top: -2px;
        font-size: 0.78em;
        padding: 0px 4px;
        margin-left: -4px;
        color: #969caf;
      }
      .selected-opt {
        color: var(--primarydark2);
        font-size: 14px;
        position: relative;
        text-transform: capitalize;
        bottom: 5px;
        margin-left: 0;
      }
    }
  }
}
.sub_filters_drop_wrap .flp-input {
  color: #212325;
  background-color: #fff;
  border: 1px solid #c5d2d8;
  padding: 10px;
  border-radius: 6px;
  height: auto;
  min-height: 40px;
  max-height: inherit;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}
.sub_filters_drop_wrap .input-conv {
  width: 100%;
}
.sub_filters_drop_wrap .errorBox {
  border: 1px solid #fc4242 !important;
}
.error {
  color: #ff0202;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
